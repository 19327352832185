import {httpGet} from '@/plugins/request'
import {getApiUrl} from "@/plugins/common"
import {getAccessToken} from '@/plugins/auth'

/**
 * 获取统计概览
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectNumbersIndex(query = [])
{
    return httpGet('/manage/project/numbers/index', query)
}

/**
 * 获取支出数据
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectNumbersCount(query = [])
{
    return httpGet('/manage/project/numbers/count', query)
}

/**
 * 获取待收款合同数据
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectNumbersPaddingPay(query = [])
{
    return httpGet('/manage/project/numbers/padding-pay', query)
}


/**
 * 获取要续费的项目
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectNumbersPaddingRenew(query = [])
{
    return httpGet('/manage/project/numbers/padding-renew', query)
}

/**
 * 增加导出功能
 * @param query
 * @returns {string}
 */
export function apiExportProjectNumbers(query) {
    query.token = getAccessToken()
    return getApiUrl('/manage/project/numbers/export', query)
}


/**
 * 获取收支列表
 *
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetProjectNumberCosts(query = [])
{
    return httpGet('/manage/project/numbers/cost', query)
}
