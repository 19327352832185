<template>
    <div>
        <el-row :gutter="10" style="text-align: center">
            <el-col :span="6" :xs="12">
                <div class="statistic-card">
                    <el-statistic :value="data.year_fee_in_total">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                              本年总收入
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6" :xs="12">
                <div class="statistic-card">
                    <el-statistic :value="data.year_fee_out_total">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                              本年总支出
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6" :xs="12">
                <div class="statistic-card">
                    <el-statistic :value="data.month_fee_in_total">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                              本月总收入
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6" :xs="12">
                <div class="statistic-card">
                    <el-statistic :value="data.month_fee_out_total">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                              本月总支出
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6" :xs="12">
                <div class="statistic-card">
                    <el-statistic :value="data.year_project_total">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                              今年项目数
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6" :xs="12">
                <div class="statistic-card">
                    <el-statistic :value="data.project_process_num">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                              进行中项目数
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6" :xs="12">
                <div class="statistic-card">
                    <el-statistic :value="data.project_padding_fee">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                              未收款的项目金额
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
            <el-col :span="6" :xs="12">
                <div class="statistic-card">
                    <el-statistic :value="data.project_padding_today_fee" :precision="2">
                        <template #title>
                            <div style="display: inline-flex; align-items: center">
                                当前待收
                            </div>
                        </template>
                    </el-statistic>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
  import {apiGetProjectNumbersIndex} from '@/api/projectNumbersApi'
  import {getCompanyId} from "@/plugins/company"

  export default {
    name: 'index',
    created() {
      this.getNumbersIndex()
    },
    data() {
      return {
        data: {}
      }
    },
    methods: {
      getNumbersIndex() {
        apiGetProjectNumbersIndex({company_id: getCompanyId()}).then(rsp => {
          this.data = rsp
        })
      }
    }
  }
</script>

<style scoped>
:global(h2#card-usage ~ .example .example-showcase) {
    background-color: var(--el-fill-color) !important;
}

.el-statistic {
    --el-statistic-content-font-size: 28px;
}

.statistic-card {
    height: 100%;
    padding: 20px;
    border-radius: 4px;
    background-color: var(--el-bg-color-overlay);
}

.statistic-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;
    color: var(--el-text-color-regular);
    margin-top: 16px;
}

.statistic-footer .footer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.statistic-footer .footer-item span:last-child {
    display: inline-flex;
    align-items: center;
    margin-left: 4px;
}

.green {
    color: var(--el-color-success);
}
.red {
    color: var(--el-color-error);
}
</style>
